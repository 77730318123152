<template>
  <div class="content-profile-drop-down p-4">
    <div class="d-flex  justify-content-center mb-2">
      <input-custom-image
        id="profile-input-img"
        v-model="photo"
        @on-change-file-info="onChangeFile"
      >
        <template #default>
          <div v-if="loading" class="user-photo picker mr-2">
            <b-spinner small variant="light" />
          </div>
          <div v-else :class="`user-circle picker mr-2`">
            <span class="letter">{{ letter }}</span>
            <span class="edit"><i class="icon-edit"></i></span>
          </div>
        </template>
        <template #filled-image>
          <div v-if="loading" class="user-photo mr-2">
            <b-spinner small variant="light" />
          </div>
          <div
            v-else-if="photo"
            class="user-photo mr-2"
            :style="`background-image: url(${photo})`"
          >
            <span class="edit"><i class="icon-edit"></i></span>
          </div>
        </template>
      </input-custom-image>
      <div class="d-flex flex-column text-truncate">
        <strong class="text-truncate">
          {{ info.name }}
        </strong>
        <div class="text-secondary small text-truncate">
          {{ info.email }}
        </div>
      </div>
    </div>
    <b-dropdown-divider class="my-3"></b-dropdown-divider>
    <b-dropdown-item
      class="mt-3 mb-4"
      :to="{ name: 'profile' }"
      @click.capture="$emit('close')"
    >
      <i class="icon-user mr-2" />
      Minha conta
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAdmin"
      :to="{ name: 'admin-homeworks' }"
      class="mt-3 mb-4"
    >
      <i class="icon-online-course mr-2"></i> Gestão de atividades
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAdmin"
      :to="{ name: 'admin-exams' }"
      class="mt-3 mb-4"
    >
      <i class="icon-trophy mr-2"></i> Gestão de provas
    </b-dropdown-item>
    <b-dropdown-item
      v-if="isAdmin"
      :to="{ name: 'admin-classes' }"
      class="mt-3 mb-4"
    >
      <i class="icon-student mr-2"></i> Gestão de turmas
    </b-dropdown-item>
    <b-dropdown-item @click="$emit('logout')" link-class="text-danger">
      <i class="icon-power mr-2"></i> Sair
    </b-dropdown-item>
  </div>
</template>
<script>
import InputCustomImage from "@/components/InputCustomImage";
import get from "lodash/get";
export default {
  components: { InputCustomImage },
  data() {
    return {
      loading: false,
      file_info: null
    };
  },
  computed: {
    letter() {
      return get(this.$user, "user.name", "User")[0];
    },
    isAdmin() {
      return get(this.$user, "user.isAdmin", false);
    },
    info() {
      if (!this.$user.user) return { name: "", email: "" };

      return this.$user.user;
    },
    photo: {
      get() {
        return get(this.$user, "user.photo", "");
      },
      set(value) {
        this.handlePicture(value);
      }
    }
  },
  methods: {
    async handlePicture(picture) {
      if (this.loading) return;

      try {
        if (picture && picture.length) {
          this.loading = true;

          const url = await this.$store.dispatch("user/uploadUserImg", {
            file: picture,
            name: this.file_info?.name || this.$user.user.name,
            email: this.$user.user.email
          });

          if (url && url.length) {
            await this.$store.dispatch("user/update", {
              ...this.$user.user,
              photo: url
            });
          }

          this.$message.success("Sua foto de perfil foi atualizada!");
        } else {
          await this.$store.dispatch("user/update", {
            ...this.$user.user,
            photo: ""
          });
        }

        await this.$store.dispatch("user/get");
      } catch (e) {
        this.$message.error("Erro ao atualizar sua foto de perfil!");
      } finally {
        this.loading = false;
      }
    },
    onChangeFile(file_info) {
      this.file_info = file_info;
    },
    goToExams() {
      this.$router.push({ name: "admin-exams" });
    }
  }
};
</script>
<style lang="scss" scoped>
.content-profile-drop-down {
  ul,
  li {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }
  .avatar-profile {
    border: 1px solid #9155ec;
    border-radius: 50%;
    position: relative;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      margin: 2px;
    }
  }

  small {
    word-break: break-word;
  }

  hr {
    margin: 8px 0;
    background-color: #d2d0d840;
  }

  ::v-deep.dropdown-item {
    font-size: 14px;
    display: flex;
    align-items: center;
    font-weight: bold;
    padding: 0;
    i {
      font-size: 14px;
    }

    &:hover {
      background: transparent;
      opacity: 0.8;
    }
    &:active {
      background: transparent;
      color: #000;
      opacity: 0.5;
    }
  }

  .user-photo {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    &.small {
      width: 36px !important;
      height: 36px !important;
    }
  }

  .picker {
    position: relative;
    width: 58px !important;
    height: 58px !important;

    .icon-user-filled {
      color: rgba(255, 255, 255, 0.8);
      font-size: 32px;
    }
  }

  .edit {
    position: absolute;
    bottom: -4%;
    right: -4%;
    background: #0a184b;
    border-radius: 50%;
    width: 19px;
    height: 19px;
    display: flex;
    align-items: center;
    justify-content: center;

    i {
      font-size: 12px;
      color: #fff;
    }
  }
}
</style>
