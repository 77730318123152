<template>
  <div
    class="template-default d-flex flex-column"
    :class="{ 'locked-menu': lockedMenu }"
  >
    <transition name="fade">
      <div class="blocking-bg d-lg-none" v-if="showMenu" @click="closeMenu" />
    </transition>

    <div class="top-bar d-flex align-items-center pt-1 pl-5 pb-2 d-lg-none">
      <div class="pl-2 flex-fill">
        <router-link
          :to="{ name: 'home' }"
          class="text-light font-weight-bold text-decoration-none"
        >
          <strong>IEB</strong>
        </router-link>
      </div>
      <profile-drop-down class="pt-1" @logout="logoutDialog = true" />
    </div>

    <div class="d-none d-lg-flex align-items-center pl-5">
      <h4 class="flex-fill text-left pl-5">
        {{ $route.meta.title ? $route.meta.title : "" }}
      </h4>
      <profile-drop-down @logout="logoutDialog = true" />
    </div>

    <button
      class="btn btn-menu d-lg-none"
      @click.stop="clickMenuButton"
      @mouseenter="clickMenuButton"
    >
      <i class="icon-menu menu d-none d-lg-block"></i>
      <i
        class="menu d-lg-none"
        :class="showMenu ? 'icon-x text-light' : 'icon-menu text-dark'"
      ></i>
    </button>

    <confirmation
      v-model="logoutDialog"
      @confirm="logout"
      text="Tem certeza que deseja sair?"
      confirm-button-text="Sair"
    />

    <aside
      class="overflow-y-scroll"
      :class="{ open: showMenu, locked: lockedMenu }"
      @mouseenter="openMenu"
      @mouseleave="closeMenu"
    >
      <div
        class="aside-content d-flex flex-column align-items-start pt-4 pb-3 text-white"
      >
        <button class="btn-lock" @click="clickLockMenu">
          <i :class="lockedMenu ? 'icon-angle-left' : 'icon-angle-right'"></i>
        </button>

        <div class="brand pt-5 pt-lg-0">
          <router-link
            :to="{ name: 'home' }"
            class="text-light font-weight-bold text-decoration-none"
          >
            <h5>IEB</h5>
          </router-link>
        </div>

        <component
          v-if="contextSideBar"
          :is="contextSideBar.component"
          @back="showRootMenu = true"
          @closeMenu="closeMenu"
          :openContext="openContext"
          @toggleContext="toggleContext"
          :indicatorClasses="indicatorClasses(contextSideBar.url_match)"
          :showMenu="showMenu"
          :lockedMenu="lockedMenu"
        />

        <div v-else class="w-100">
          <router-link
            :to="{ name: 'home' }"
            class="btn mt-3 home"
            @click.native="closeMenu"
          >
            <i class="icon-dashboard"></i>
            <span>Início</span>
          </router-link>
          <router-link
            :to="{ name: 'content' }"
            class="btn home"
            @click.native="closeMenu"
          >
            <i class="icon-youtube"></i>
            <span>Conteúdo</span>
          </router-link>
          <router-link
            :to="{ name: 'exams' }"
            class="btn home"
            @click.native="closeMenu"
          >
            <i class="icon-trophy"></i>
            <span>Provas</span>
          </router-link>
          <router-link
            :to="{ name: 'bibliography' }"
            class="btn home"
            @click.native="closeMenu"
          >
            <i class="icon-content"></i>
            <span>Bibliografia</span>
          </router-link>
          <div v-if="lockedMenu || showMenu" class="section-title">
            <hr class="bg-light" />
          </div>
          <div
            v-if="lockedMenu || showMenu"
            class="section-title context-option"
          >
            <a
              target="_blank"
              class="text-decoration-none"
              href="https://www.instagram.com/comunidadevideira/"
            >
              <small class="text-secondary d-flex align-items-center">
                <i class="icon-instagram"></i>
                @comunidadevideira
              </small>
            </a>
          </div>
          <div
            v-if="lockedMenu || showMenu"
            class="section-title context-option"
          >
            <a
              target="_blank"
              class="text-decoration-none"
              href="https://www.youtube.com/user/VideiraTV"
            >
              <small class="text-secondary d-flex align-items-center">
                <i class="icon-youtube"></i>
                youtube.com/user/VideiraTV
              </small>
            </a>
          </div>
          <div
            v-if="lockedMenu || showMenu"
            class="section-title context-option"
          >
            <small class="text-secondary">
              © Instituto Eurico Bergsten
            </small>
          </div>
          <div
            v-if="lockedMenu || showMenu"
            class="section-title context-option"
          >
            <small class="text-secondary">
              © Comunidade Videira
            </small>
          </div>
        </div>
      </div>
    </aside>

    <main
      class="flex-fill position-relative"
      :class="{
        'pl-2': lockedMenu
      }"
    >
      <div class="container-fluid py-3 slot-wrapper">
        <slot v-if="!loading" />
        <div v-else class="text-center p-5">
          <b-spinner variant="primary" style="width: 3rem; height: 3rem;" />
        </div>
      </div>
    </main>
  </div>
</template>

<script>
import ProfileDropDown from "@/components/ProfileDropDown/ProfileDropDown";
import { mapState } from "vuex";

export default {
  components: { ProfileDropDown },
  data() {
    return {
      showMenu: false,
      logoutDialog: false,
      menuButtonTimeout: null,
      lockedMenu: !window.localStorage.getItem("_unlockedMenu"),
      showRootMenu: false,
      openContext: ""
    };
  },
  watch: {
    $route() {
      this.showRootMenu = false;
    }
  },
  computed: {
    ...mapState(["loading"]),
    contextSideBar() {
      if (this.showRootMenu || !this.$route.meta.sidemenu) return false;

      return this.$route.meta.sidemenu;
    },
    reversedCourses() {
      return [...this.courses].reverse();
    }
  },
  methods: {
    indicatorClasses(context) {
      if (
        this.$router.currentRoute.path.match(context) &&
        !this.showMenu &&
        !this.lockedMenu
      ) {
        return "active-item-indiciator";
      }

      return "";
    },
    toggleContext(context) {
      if (this.openContext === context) {
        this.openContext = null;
      } else {
        this.openContext = context;
      }
    },
    clickLockMenu() {
      this.lockedMenu = !this.lockedMenu;
      if (this.lockedMenu) {
        window.localStorage.removeItem("_unlockedMenu");
      } else {
        this.showMenu = false;
        window.localStorage.setItem("_unlockedMenu", 1);
      }
    },
    setMenu(value, timeout) {
      if (this.menuButtonTimeout) {
        clearTimeout(this.menuButtonTimeout);
      }
      this.menuButtonTimeout = setTimeout(() => {
        this.showMenu = value;
      }, timeout);
    },
    openMenu() {
      this.setMenu(true, 100);
    },
    closeMenu() {
      this.setMenu(false, 100);
    },
    clickMenuButton() {
      this.setMenu(!this.showMenu, 10);
    },
    logout() {
      this.$store.dispatch("logout").then(() => {
        this.$router.push({ name: "login" });
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/_variables.scss";

.template-default {
  min-height: 100vh;
  display: flex;
  position: relative;

  &.sale {
    background: #e63946;

    ::v-deep footer * {
      color: white !important;
    }
  }

  .top-bar {
    background: #f8f8f9;
    width: 100%;
    @media screen and (max-width: 991px) {
      background: $primary;
    }
  }

  .btn-menu {
    position: fixed;
    width: 72px;
    top: 0;
    z-index: 4;
    & > * {
      @media screen and (max-width: 991px) {
        color: #fff !important;
        font-size: 24px !important;
      }
    }
  }

  .blocking-bg {
    position: fixed;
    z-index: 3;
    background: #33333320;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
  }

  aside {
    position: fixed;
    z-index: 3;
    left: 0;
    top: 0;
    bottom: 0;
    width: 72px;
    display: flex;
    flex-direction: column;
    transition: all 0.1s;

    .brand {
      padding-left: 26px;
    }

    @media screen and (max-width: 575px) {
      width: 0px;
    }

    .aside-content {
      flex: 1 1 auto;
      background: $primary;
      transition: width 0.15s;
      box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);

      .btn-lock {
        position: absolute;
        right: 8px;
        top: 101px;
        border: none;
        border-radius: 24px;
        height: 24px;
        width: 24px;
        background: white;
        display: none;
        z-index: 3;
        box-shadow: 0px 0px 8px rgba(0, 0, 0, 0.25);
        justify-content: center;
        align-items: center;
        padding: 0;
        color: rgba(51, 51, 51, 0.6);

        i {
          font-size: 16px !important;
        }

        @media screen and (max-width: 575px) {
          display: none !important;
        }
      }

      .context-option {
      }

      ::v-deep.btn {
        width: 100%;
        color: rgba(255, 255, 255, 0.8);
        display: flex;
        cursor: pointer;
        padding-top: 12px;
        padding-bottom: 12px;
        justify-content: flex-start;

        i {
          color: #928f98;
        }

        &.router-link-active:not(.home):not(.context-option),
        &.router-link-exact-active {
          color: white;
          font-weight: bold;
          border-radius: 0;
          background: $primary;

          i {
            color: white;
          }

          @media screen and (min-width: 576px) {
            padding-left: 13px;
            border-right: 4px solid white;
          }
          @media screen and (max-width: 575px) {
            padding-left: 9px;
            border-right: 4px solid white;
          }
        }

        span {
          margin-left: 12px;
          white-space: nowrap;
          flex: 1 1 auto;
          text-align: left;
          text-overflow: ellipsis;
          overflow: hidden;
        }

        &:hover {
          color: white;
        }
      }

      .active-item-indiciator {
        font-weight: bold;
        border-right: 4px solid white;
        background: rgba(255, 255, 255, 0.05);

        @media screen and (min-width: 576px) {
          padding-left: 13px;
        }
        @media screen and (max-width: 575px) {
          padding-left: 9px;
        }

        i {
          color: white;
        }
      }
    }

    &.locked {
      @media screen and (min-width: 576px) {
        width: 300px;
        padding-right: 20px;

        .btn-lock {
          display: flex;
        }
      }
    }

    &.open {
      width: 300px;
      padding-right: 20px;

      .btn-lock {
        display: flex;
      }
    }

    &:not(.open):not(.locked) {
      box-shadow: 2px 0 8px rgba(0, 0, 0, 0.2);
    }
  }

  i {
    font-size: 24px;
    min-width: 46px;
    transition: all 0.2s;

    &.menu {
      font-size: 32px;
      line-height: 44px;
      color: rgba(51, 51, 51, 0.6);
    }
  }

  .open-submenu {
    .icon-angle-right {
      transform: rotate(90deg);
    }
  }

  main {
    background: #f8f8f9;
    position: relative;
    width: 100%;

    @media screen and (min-width: 576px) {
      width: calc(100% - 72px);
      margin-left: 72px;
    }
  }

  .slot-wrapper {
    display: flex;
    flex-direction: column;
    transition: 0.15s padding ease;
    min-height: calc(100vh - 86px);
  }

  &.locked-menu {
    .slot-wrapper {
      @media screen and (min-width: 992px) {
        padding-left: 248px;
      }
    }
  }

  .submenu {
    width: 100%;

    a {
      padding-left: 32px !important;
    }
  }

  ::v-deep .account-menu {
    position: absolute;
    top: 24px;
    right: 24px;
    z-index: 1;

    .dropdown-toggle {
      border-radius: 32px;
      max-width: 32px;
      min-width: 32px;
      max-height: 32px;
      min-height: 32px;
      border: 1px solid #9155ec;
      padding: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &::after {
        display: none;
      }
    }
  }

  .rotate-90-clock {
    transform: rotate(90deg);
  }

  .section-title {
    font-family: Poppins;
    font-size: 12px;
    color: #d2d0d8;
    padding-top: 12px;
    padding-bottom: 12px;
    padding-left: 28px;
  }
}
</style>
