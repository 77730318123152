<template>
  <div class="profile-drop-down">
    <b-dropdown
      right
      no-caret
      size="lg"
      variant="sm"
      class="d-none d-lg-block"
      menu-class="resize-dropdown"
      toggle-class="text-decoration-none"
    >
      <template #button-content>
        <button class="btn avatar-perfil">
          <b-spinner v-if="loading" variant="primary" small></b-spinner>
          <div
            v-if="photo"
            class="user-photo small mr-2"
            :style="`background-image: url(${photo})`"
          ></div>
          <div v-else class="user-circle mr-2">
            <span class="letter">{{ letter }}</span>
          </div>
          {{ $user.user ? $user.user.name : "" }}
          <h5 class="p-0 m-0"><i class="icon-angle-down ml-1" /></h5>
        </button>
      </template>
      <drop-down-content
        @set-loading="setLoading"
        @logout="$emit('logout')"
        @close="close"
      />
    </b-dropdown>

    <div class="d-lg-none">
      <button class="btn avatar-perfil text-light" @click="visible = true">
        <div
          v-if="photo"
          class="user-photo small mr-2"
          :style="`background-image: url(${photo})`"
        ></div>
        <div v-else class="user-circle mr-2">
          <span class="letter">{{ letter }}</span>
        </div>
        <h5 class="p-0 m-0"><i class="icon-angle-down ml-1" /></h5>
      </button>
    </div>

    <responsive-modal v-model="visible" class="d-lg-none">
      <drop-down-content
        @set-loading="setLoading"
        @logout="$emit('logout')"
        @close="close"
      />
    </responsive-modal>
  </div>
</template>
<script>
import DropDownContent from "./Content.vue";
import vClickOutside from "v-click-outside";
import get from "lodash/get";

export default {
  components: { DropDownContent },
  directives: {
    clickOutside: vClickOutside.directive
  },
  data() {
    return {
      visible: false,
      dropdownOpened: false,
      loading: false
    };
  },
  mounted() {
    this.$root.$on("bv::dropdown::show", bvEvent => {
      this.dropdownOpened = true;
    });
  },
  computed: {
    letter() {
      return get(this.$user, "user.name", "User")[0];
    },
    info() {
      if (!this.$user.user) return { name: "", email: "" };

      return this.$user.user;
    },
    photo() {
      return get(this.$user, "user.photo", false);
    }
  },
  methods: {
    open() {
      this.visible = true;
    },
    close() {
      this.visible = false;
    },
    setLoading(v) {
      this.loading = v;
    }
  }
};
</script>
<style lang="scss" scoped>
.profile-drop-down {
  display: flex;
  justify-content: flex-end;
  background: transparent;
  ::v-deep.dropdown-menu.show {
    min-width: 265px !important;
    max-width: 282px !important;
    transform: translate3d(-180px, 50px, 0px) !important;
  }

  .btn.avatar-perfil:active {
    background: rgba(145, 85, 236, 0.1);
    border-radius: 4px;
  }

  .user-photo {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    object-fit: cover;
    display: flex;
    justify-content: center;
    align-items: center;

    &.small {
      width: 36px !important;
      height: 36px !important;
    }
  }
}
</style>
