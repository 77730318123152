<template>
  <div class="component-input-image">
    <input
      :id="id"
      type="file"
      accept="image/*"
      class="input-file"
      @change="fileChange($event.target.files)"
    />
    <!-- Upload -->
    <button type="button" class="btn upload-image" @click="changeImage">
      <div :class="{ 'd-none': value }">
        <slot></slot>
      </div>
      <slot v-if="value" name="filled-image"></slot>
    </button>
    <button v-if="value" class="btn remove-image" @click="resetImage">
      Remover
    </button>
    <!-- Success -->
  </div>
</template>

<script>
export default {
  props: {
    accepted_extensions: {
      type: Array,
      default: () => ["png", "jpg", "jpeg", "webp"]
    },
    value: {
      type: [File, String],
      default: null
    },
    id: {
      type: String,
      default: "file-img"
    },
    dimensions: {
      type: String
    }
  },
  computed: {
    isValid() {
      let valid = false;
      for (const key in this.form) {
        valid = Array.isArray(this.form[key])
          ? this.form[key].length
          : !!this.form[key];
      }
      return valid;
    }
  },
  methods: {
    changeImage() {
      document.getElementById(this.id).click();
    },
    resetImage() {
      this.$emit("input", null);
      document.getElementById(this.id).type = "text";
      document.getElementById(this.id).type = "file";
    },
    getExtension(name) {
      const filename = `${name}`.toLowerCase();
      return /[.]/.exec(filename) ? /[^.]+$/.exec(filename)[0] : undefined;
    },
    fileChange(files) {
      if (!files || !files[0]) return;

      this.$emit("on-change-file-info", files[0]);

      const sizes = ["Bytes", "KB", "MB", "GB", "TB"];
      const i = parseInt(Math.floor(Math.log(files[0].size) / Math.log(1024)));
      const size =
        Math.round(files[0].size / Math.pow(1024, i), 2) + " " + sizes[i]; // convert to mb and show unit meansurement

      if (size.match("MB") && /([^MB]*)MB/.exec(size)[1] > 5) {
        this.$message.error("O arquivo deve ter no máximo 5 mb");
        return;
      }

      if (
        !this.accepted_extensions.includes(this.getExtension(files[0].name))
      ) {
        this.$message.error("O arquivo deve ter extensão .png, .jpg ou .webp");
        return;
      }

      this.getImage(files[0]).then(img => {
        this.$emit("input", img);
      });
    },
    getImage(file) {
      return new Promise((resolve, reject) => {
        const fReader = new FileReader();
        const img = document.createElement("img");

        fReader.onload = () => {
          img.src = fReader.result;
          resolve(this.getBase64Image(img));
        };

        fReader.readAsDataURL(file);
      });
    },
    getBase64Image(img) {
      const dataURL = img.src;
      return dataURL;
    }
  }
};
</script>
<style lang="scss" scoped>
.component-input-image {
  .upload-image {
    padding: 0 !important;
    margin: 0 !important;
    min-height: fit-content !important;
    min-width: fit-content !important;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .remove-image {
    padding: 0 !important;
    margin: 0 !important;
    min-height: fit-content !important;
    min-width: fit-content !important;
    width: 100%;
    text-align: center;
    color: #ff757e !important;
    font-size: 10px;
  }

  .container-image {
    position: relative;

    img {
      max-width: 100%;
      border-radius: 4px;
      transition: filter 0.2s;
    }
  }
  .input-file {
    opacity: 0 !important;
    width: 100%;
    height: 100%;
    top: 1000;
    left: 1000;
    position: absolute;
    visibility: hidden;
    cursor: pointer;
  }
}
</style>
